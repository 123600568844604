.bap_page_wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
}


.bap_page_header_with_logo{
    height: 45px;
    width: 100%;
    background-color: var(--bap_primary_background);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box !important;
    padding: 0px 10px;
    box-shadow: var(--bap_box_shadow);
    position: relative;
    z-index: 100000;
    padding: 10px;
}.bap_page_header_with_logo img{
    height: 30px;
    width: auto;
    margin-right: 10px;
}


.bap_page_wrapper_create_atlas_message{
    height: calc(100% - 90px);
    position: relative;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: var(--bap_primary_background);
    display: grid;
    grid-template-columns: 280px 1fr;
}

@media screen and (max-width: 1250px){
    .bap_page_wrapper_create_atlas_message{
        grid-template-columns: 1fr;
    }
}


.bap_toolbar{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 10px;
}



/*Content base wrapper*/
.bap_list_content_base_wraper{
    height: 100%;
    width: 100%;
}




/*Nav Bar*/

.bap_navbutton_wrapper{
    height: 30px;
    width: 30px;
    background-color: var(--bap_primary_background);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}.bap_navbutton_wrapper:hover{
    background-color: var(--bap_menu_background);
}



.bap_nav_box_wrapper_main{
    height: 100vh;
    width: 280px;
    position: absolute;
    right: 0px;
    top: 0px;
    box-sizing: border-box !important;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bap_main_content_wrapper_menu_slider{
    height: 100%;
    width: 100%;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    border-radius: 5px;
}

.button_section_close_menu{
    height: 45px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0px 10px;
    border-radius: 5px;
    box-sizing: border-box !important;
}.button_section_close_menu span{
    font-size: 16px;
    font-weight: 600;
    color: var(--bap_primary_font);
}

.bap_close_btn_menu{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-color: var(--bap_primary_background);
    border-radius: 5px;align-items: center;
}.btn_close_icon{
    font-size: 20px;
}.bap_close_btn_menu:hover{
    background-color: var(--bap_menu_hover_background);
}


/*Listing the main*/
.bap_menu_content_wrapper_list_items{
    height: calc(100% - 45px);
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    box-sizing: border-box !important;
    padding: 5px;
    display: flex;
    flex-direction: column;
}


.bap_nav_list_item{
    height: 35px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box !important;
    padding: 10px 10px;
    background-color: var(--bap_primary_background);
    border-radius: 5px;
}.bap_nav_list_item:hover{
    background-color: var(--bap_list_item_hover);
}

.bap_nav_list_item span{
    font-size: 14px;
    font-weight: 500;
    color: var(--bap_primary_font);
}



.bap_nav_list_item_active{
    background-color: var(--reybex_orange);
}