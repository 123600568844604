.bap_options_list_container{
    height: 100%;
    width: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}


.bap_option_item_list{
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}


.bap_option_item{
    box-sizing: border-box;
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    background-color: var(--bap_primary_background);
    border-bottom: 0.5px solid var(--bap_light_border);
    cursor: pointer;
}.bap_option_item span{
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;          
    text-overflow: ellipsis;
    display: inline-block;     
    max-width: 250px;
}.bap_option_item:hover{
    background-color: var(--bap_menu_hover_background);
}



/*Defaul option*/
.bap_item_is_default{
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
}

.bap_item_is_default_icon{
    height: 8px;
    width: 8px;
    background-color: var(--bap_badge_green);
    border-radius: 100px;
}


.bap_item_is_no_default_icon{
    height: 8px;
    width: 8px;
    background-color: var(--bap_primary_border);
    border-radius: 100px;
}


/*Button*/
.bap_item_status_for_active{
    height: 16px;
    width: 40px;
    padding: 2px 10px;
    background-color: var(--bap_badge_green);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}.bap_item_status_for_active span{
    font-size: 12px;
    font-weight: 500;
    color: var(--bap_badge_green_font);
}


.bap_item_status_for_inactive{
    height: 16px;
    width: 40px;
    padding: 2px 10px;
    background-color: var(--bap_badge_red);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}.bap_item_status_for_inactive span{
    font-size: 12px;
    font-weight: 500;
    color: var(--bap_badge_red_font);
}

