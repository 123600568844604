.bap_button{
    height: 28px;
    width: fit-content;
    background-color: var(--reybex_orange);
    outline: none;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}.bap_button:hover{
    background-color: var(--reybex_orange_hover);
}



.bap_button_full_width{
    width: 100%;
    height: 28px;
    background-color: var(--reybex_orange);
    outline: none;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}.bap_button_full_width:hover{
    background-color: var(--reybex_orange_hover);
}


/*Loading*/

.bap_loading_buttom_icon{
    height: 26px;
    width: 26px;
}





/*Button Group*/

.bap_button_button_group{
    height: 28px;
    width: 100%;
    display: flex; 
    align-items: center;
    gap: 10px;
}