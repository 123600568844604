


.bap_input_wrapper{
    width: 100%;
    height: 28px;
    justify-content: space-between;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
}.bap_input_wrapper input{
    height: 28px;
    width: 50%;
    box-sizing: border-box !important;
    padding: 0px 10px;
    border: 2px solid var(--bap_primary_border);
    border-radius: 3px;
}


.bap_input_wrapper span{
    font-size: 14px;
    color: var(--bap_primary_font);
    font-weight: 500;
    margin-right: 10px;
}


/*Inline Input*/

.bap_inline_input_wrapper{
    width: 100%;
    height: fit-content;
    box-sizing: border-box !important;
    display: flex;
    flex-direction: column;
}

.bap_inline_input_wrapper input{
    height: 28px;
    width: 100%;
    box-sizing: border-box !important;
    padding: 0px 10px;
    border: 2px solid var(--bap_primary_border);
    border-radius: 3px;
}


.bap_inline_input_wrapper textarea{
    height: 150px;
    width: 100%;
    box-sizing: border-box !important;
    padding: 10px;
    border: 2px solid var(--bap_primary_border);
    border-radius: 3px;
    resize: none;
}.bap_inline_input_wrapper textarea:focus{
    outline: none;
}


.bap_inline_input_wrapper label{
    font-size: 14px;
    color: var(--bap_primary_font);
    font-weight: 500;
    margin-bottom: 5px;
}


