.bap_card_slider_container{
    position: absolute;
    z-index: 100000;
    top: 0px;
    right: 0px;
    height: 100vh;
    width: 500px;
    padding: 10px;
    box-sizing: border-box;
}


.bap_card_slider_card{
    height: 100%;
    width: 100%;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    border-radius: 5px;
    border: 1px solid var(--bap_primary_border);
    box-sizing: border-box;
    padding: 20px;
}



.bap_card_slider_header_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}.bap_card_slider_header_bar h3{
    margin: 0px;
}


.bap_close_card_slider{
    height: 32px;
    width: 32px;
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in; 
    cursor: pointer;
}.bap_close_card_slider:hover{
    background-color: var(--bap_menu_hover_background);
    cursor: pointer;
}


.bap_card_slider_content{
    height: calc(100% - 32px);
    width: 100%;
}