.bap_column_grid_main_wrapepr{
    height: auto;
    width: 100%;
    display: grid;
    flex-direction: column;
    grid-template-columns: 1;
    grid-template-rows: auto;
    gap: 15px;
    padding: 10px;
    box-sizing: border-box !important;
}