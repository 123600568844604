

.bap_content_base_wrapper_col2{
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--bap_primary_background);
    position: sticky;
    overflow-y: auto;
}