.bap_top_section_wrapper_main{
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 10px;
    box-sizing: border-box !important;
    padding: 10px
}



.bap_tob_section_tab_wrapper{
    height: 30px;
    width: auto;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
}.bap_tob_section_tab_wrapper span{
    font-size: 14px;
    color: var(--bap_primary_font);
    text-wrap: nowrap;
}.bap_tob_section_tab_wrapper:hover{
    background-color: var(--reybex_orange);
}.bap_tob_section_tab_wrapper:hover span{
    color: white;
}


.bap_top_tab_active{
    background-color: var(--reybex_orange);
}.bap_top_tab_active span{
    color: white;
}




.bap_tab_top_section_active{
    background-color: var(--reybex_orange);
    color: white;
    border-radius: 5px;
    padding: 0px 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: var(--bap_box_shadow);
}.bap_tab_top_section_active:hover{
    background-color: var(--reybex_orange_hover);
}.bap_tab_top_section_active:hover span{
    color: white;
}.bap_tab_top_section_active span{
    font-size: 14px;
    color: white;
    text-wrap: nowrap;
}