.bap_table_container {
    height: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}

/* Table Header */
.bap_tabler_header {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--bap_menu_background);
    border-radius: 5px;
    box-sizing: border-box !important;
}

.bap_table_header_item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    height: 35px;
    flex-grow: 1;  /* Verteilung des Platzes */
    flex-basis: 0; /* Flexbasis sorgt dafür, dass die Items dieselbe Breite wie im Body haben */
    justify-content: flex-start; /* Links ausgerichteter Text */
    text-align: left;
    box-sizing: border-box !important;
}

.bap_table_header_item span {
    font-size: 14px;
    font-weight: 600;
    color: var(--bap_primary_font);
    text-align: left;
}

/* Table Body */
.bap_table_body {
    height: fit-content;
    width: 100%;
}

.bap_table_body_row {
    height: 35px;
    display: flex;
    border-radius: 5px;
    background-color: transparent;
}.bap_table_body_row:hover{
    background-color: var(--bap_table_row_hover);
}

.bap_table_body_item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0px 10px;
    cursor: pointer;
    flex-grow: 1;  /* Verteilung des Platzes */
    flex-basis: 0; /* Flexbasis sorgt dafür, dass die Items dieselbe Breite wie im Header haben */
    border-radius: 5px;
}.bap_table_body_item:hover{
    background-color: var(--bap_table_item_hover);
}

.bap_table_body_item span {
    font-size: 14px;
    font-weight: 500;
    color: var(--bap_primary_font);
    text-align: left;
}




/*Card container*/
.bap_card_container_wrapper{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.bap_card_item_wrapper{
    height: auto;
    box-sizing: border-box !important;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--bap_menu_background);
    background-color: var(--bap_primary_background);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}.bap_card_item_wrapper:hover{
    background-color: var(--bap_menu_hover_background);
}


.bap_card_content_wrapper{
    height: 100%;
    width: 100%;
    display: flex;
}


.bap_card_square_item{
    height: 40px;
    width: 40px;
    background-color: var(--bap_menu_background);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.bap_card_text_information_box{
    margin-left: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.bap_card_text_information_box span{
    font-size: 14px;
    font-weight: 600;
    color: var(--bap_primary_font);
}.bap_card_text_information_box p{
    margin: 0px;
    font-size: 12px;
}


/*Filter in Table header*/

.bap_table_filter{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    box-sizing: border-box !important;
}.bap_table_filter:hover{
    background-color: var(--bap_menu_hover_background);
}

.filterable_icon{
    font-size: 12px;
    margin-left: 10px;
    margin-right: 5px;
    cursor: pointer;
}


.bap_filter_wrapper{
    position: relative;
    height: 100%;
    flex-grow: 1;
    flex-basis: 0;
}


.bap_filter_box_wrapper{
    gap: 15px;
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    height: fit-content;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
    flex-direction: column;
}