.bap_accordion_wrapper_main{
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box !important;
    position: relative;
}


/*Accordion Tab clickable*/

.bap_accordion_tab{
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
}


.bap_accordion_tab_header_button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 15px 20px;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}.bap_accordion_tab_header_button:hover{
    background-color: var(--bap_menu_hover_background);
}


/*Content Wrapper*/
.bap_accordion_content_wrapper{
    height: fit-content;
    width: 100%;
    padding: 20px;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    box-sizing: border-box !important;
    border-radius: 0px 0px 5px 5px;
}

.bap_accordion_display_grid{
    margin-top: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    box-sizing: border-box !important;
    width: 100%;
}

.bap_accordion_display_text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.bap_accordion_display_text span{
    font-size: 14px;
    color: var(--bap_primary_font);
}.bap_accordion_display_text_label{
    font-weight: 600;
}