.bap_search_bar_wrapper{
    height: 28px;
    width: 250px;
    position: relative;
    background-color: var(--bap_primary_background);
    border: 2px solid var(--bap_primary_border);
    box-sizing: border-box !important;
    border-radius: 5px;
}.bap_search_bar_wrapper input{
    height: 100%;
    width: 100%;
    box-sizing: border-box !important;
    border: none;
    padding-left: 10px;
    background-color: transparent;
    position: absolute;
    z-index: 1;
}


.bap_search_icon_wrapper{
    height: 100%;
    position: absolute;
    z-index: 0;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bap_primary_border);
    box-sizing: border-box !important;
    right: 0px;
}

