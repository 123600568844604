.bap_page_loading_wrapper{
    position: absolute;
    z-index: 100000;
    height: 100vh;
    width: 100vw;
    top: 0px;
}


.bap_page_loader_container{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bap_page_loader_background{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--bap_loading_background);
    opacity: 0.4;
    z-index: 0;
}


.bap_loading_spinner{
    height: 40px;
    width: 40px;
    box-sizing: border-box !important;
    border-radius: 100px;
    border: 4px solid var(--bap_primary_background);
    border-top: 4px solid var(--bap_primary_font);
    animation: spin 0.5s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }   /* Startet bei 0 Grad */
    100% { transform: rotate(360deg); } /* Rotiert 360 Grad */
  }