
.bap_form_box_wrapper{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box !important;
}.bap_FormBox_inline_header {
    font-size: 14px;
    font-weight: 700;
}


.bap_header_section_form_box{
    font-size: 16px;
    margin: 0px;
    margin-bottom: 10px;
    color: var(--bap_primary_font);
}

