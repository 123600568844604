

.bap_atlas_message_grid_wrapper{
    height: fit-content;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    box-sizing: border-box !important;
    padding: 10px;
}


.bap_atlas_message_grid_item{
    height: fit-content;
    width: 100%;
    box-sizing: border-box !important;
    display: flex;
    flex-direction: column;
    gap: 15px;
}


@media screen and (max-width: 1250px){
    .bap_atlas_message_grid_wrapper{
        grid-template-columns: 1fr;
    }
}