

body{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    top: 0px;
    left: 0px;
    margin: 0px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}


:root{
    --bap_primary_background: #ffffff;
    --bap_menu_background: #f1f1f1;
    --bap_menu_hover_background: #dbdbdb;
    --bap_primary_border: #e2e2e2;
    --bap_light_border: #f1f1f1;
    --bap_primary_font: #0f0f0f;
    --bap_loading_background: #000000;

    --reybex_orange: #f15628;
    --reybex_orange_hover: #f76c41;
 

    --bap_list_item_hover: #f1f1f1;

 
    --bap_box_shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


    /*Disabled:*/ 
    --bap_input_disabled: #e2e2e2;


    /*Badge*/
    --bap_badge_green: #5ff45f; 
    --bap_badge_green_font: #153f15;

    --bap_badge_red: #c1c1c1;
    --bap_badge_red_font: #2b2b2b;



    /*Table item Hover*/
    --bap_table_row_hover: #f7f7f7;
    --bap_table_item_hover: #f3f3f3;
}


ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

li{
    padding: 0px;
    margin: 0px;
}


input{
    outline: none;
}
input:focus{    
    outline: none;
}



/*Main Icons*/

.bap_add_item{
    font-size: 18px;
    color: var(--bap_menu_background);
}






/*Scrollbar*/
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Hintergrund der Scrollbar */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Farbe des Scrollbalkens */
::-webkit-scrollbar-thumb {
    background-color: #dcdcdc; /* Farbe des Balkens */
    border-radius: 100px; /* Abgerundete Ecken */
    width: 10px;
}

/* Hover-Effekt für den Scrollbalken */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
