.pn_dropdown_container_classic{
    height: fit-content;
    width: 50%;
    max-width: 50%;
    position: relative;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pn_dropdown_btn_select{
    height: 28px;
    width: 100%;
    background-color: var(--bap_primary_background);
    border: 2px solid var(--bap_primary_border);
    border-radius: 3px;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    cursor: pointer;
}

.pn_dropdown_btn_select span{
    font-size: 14px;
    color: var(--bap_primary_font);
    text-wrap: nowrap;
    max-width: 250px; 
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1250px){
    .pn_dropdown_btn_select span{
        max-width: 150px;
    }
}

.pn_dropdown_icon{
    color: var(--bap_primary_font);
}


.pn_dropdown_btn_select:hover{
    background-color: var(--pn_list_item_hover);
}


.pn_dropdown_label {
    font-size: 14px;
    font-weight: 500;
    color: var(--bap_primary_font);
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}


@media screen and (max-width: 1250px) {
    .pn_dropdown_label {
        white-space: nowrap;
        width: 250px; /* Festgelegte Breite */
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


/*Provider*/
.pn_dropdown_provider{
    height: fit-content;
    width: 100%;
    position: relative;
}


.pn_dropdown_result_wrapper{
    position: absolute;
    top: 33px; 
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: 250px;
    background-color: var(--bap_primary_background);
    border: 1px solid var(--pn_border_primary_grey);
    border-radius: 5px;
    box-shadow: var(--bap_box_shadow);
    padding: 3px;
    box-sizing: border-box !important;
    overflow-y: auto;
}

.pn_dropdown_item{
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box !important;
    padding: 0px 10px;
    border-radius: 5px;
    cursor: pointer;
}.pn_dropdown_item:hover{
    background-color: var(--bap_list_item_hover);
}

.pn_dropdown_item span{
    font-size: 14px;
    color: var(--pn_primary_font);
    cursor: pointer;
    text-wrap: nowrap;
}



.bap_dropdown_wrapper_main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/*Dropdown Button*/

.bap_drop_button_wrapper{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bap_add_new_code_item{
    height: 28px;
    width: 28px;
    background-color: var(--reybex_orange);
    margin-left: 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}.bap_add_new_code_item:hover{
    background-color: var(--reybex_orange_hover);
}



/*Dropdown List search*/
.bap_search_bar_item_inside_dropdown{
    height: 28px;
    width: 100%;
}

.bap_search_bar_item_inside_dropdown input{
    height: 28px;
    width: 100%;
    background-color: var(--bap_primary_background);
    border: none;
    border-bottom: 1px solid var(--bap_primary_border);
    display: flex;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box !important;
}


.bap_dropdown_label_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    box-sizing: border-box !important;
    padding-right: 50px;
}



/*FullWith Dropdown*/
.bap_main_calssic_full_widt_wrapper{
    height: fit-content;
    width: 100%;
    position: relative;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
    justify-content: end;
}