
.bap_setup_card_wrapper{
    height: fit-content;
    width: 400px;
    background-color: var(--bap_primary_background);
    box-shadow: var(--bap_box_shadow);
    box-sizing: border-box !important;
    padding: 20px;
    border-radius: 4px;
}

.bap_setup_card_wrapper h4{
    margin: 0px;
    margin-bottom: 25px;
}