.bap_tab_handler_wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box !important;
    padding: 10px;
    background-color: var(--bap_menu_background);
    box-shadow: var(--bap_box_shadow);
    border-radius: 0px 5px 5px 0px;
    overflow-y: auto;
    position: relative;
    z-index: 1000;
}

.bap_tab_handler_content_box{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.bap_tab_item_box{
    height: 30px;
    width: 100%;
    box-sizing: border-box !important;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    margin-bottom: 10px;
}.bap_tab_item_box:hover{
    background-color: var(--bap_menu_hover_background);
}


.bap_tab_active{
    background-color: var(--bap_menu_hover_background);
}

.bap_tab_item_box span{
    font-size: 14px;
    color: var(--bap_primary_font);
    font-weight: 400;
}



@media screen and (max-width: 1250px){
    .bap_tab_handler_wrapper{
        border-radius: 5px;
        display: none;
    }
}