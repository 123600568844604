.bap_atlas_setup{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bap_primary_background);
}



.bap_form_wrapper_setup{
    display: flex;
    flex-direction: column;
    gap: 20px;
}